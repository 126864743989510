import { useState, useEffect, useRef } from "react";
import { MdPlayArrow, MdPause, MdForward10, MdReplay10, MdAudioFile } from "react-icons/md";
import { getInlineLoader } from '../Helpers';

export default function AudioPlayer({ title, url }) {

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setPlaying(!playing);
  };

  const seekForward = () => {
    audioRef.current.currentTime += 10;
  };

  const seekBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  const handleAudioLoaded = () => {
    setLoading(false);
    setReady(true);
  };

  const handleAudioError = () => {
    setLoading(false);
    // Handle error appropriately
  };

  const init = () => {
    if (url) {
      setLoading(true);
    }
  };

  useEffect(() => {
    init();
  }, [url]);

  return (
    <div className="container">
      {loading ? (
        <div className="mSupportLoading">{getInlineLoader()}</div>
      ) : (
        ready && (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body text-center">
                  <MdAudioFile size={90} />

                  <h6
                    className="font-semi-bold mt-4 mb-4"
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    {title}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-block" onClick={seekBackward}>
                <MdReplay10 size={30} />
              </button>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-block" onClick={togglePlayPause}>
                {playing ? <MdPause size={30} /> : <MdPlayArrow size={30} />}
              </button>
            </div>
            <div className="col-4 text-center">
              <button className="btn btn-block" onClick={seekForward}>
                <MdForward10 size={30} />
              </button>
            </div>


          </div>
        )
      )}

      <audio
        ref={audioRef}
        src={url}
        onLoadedData={handleAudioLoaded}
        onError={handleAudioError}
        preload="auto"
      />
    </div>
  );
}
