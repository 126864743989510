import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import AccountHome from "../views/AccountHome";
import MyPurchases from "../views/MyPurchases";
import MyDigitalPurchases from "../views/DigitalPurchases";
import SavedBooks from "../views/SavedBooks";
import MyPhysicalPurchases from "../views/PhysicalPurchases";
import Notifications from '../views/Notifications';
import Login from "../views/Login";


export default function Account(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if (appContext.auth && appContext.currentUserData) {
      if (
        !appContext.navSubItem &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <AccountHome />
      } else if (
        appContext.navSubItem === 'my-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyPurchases />
      } else if (
        appContext.navSubItem === 'my-digital-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyDigitalPurchases />
      } else if (
        appContext.navSubItem === 'my-physical-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyPhysicalPurchases />
      } else if (
        appContext.navSubItem === 'notifications' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Notifications />
      } else if (
        appContext.navSubItem === 'saved-books' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <SavedBooks/>
      }
    } else {
      return <Login />
    }


    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem])

  //return desired view
  return view;
}